import React from "react"
import Icons from "./icons"
import ScrollLock from "react-scrolllock"
import { useSpring, animated } from "react-spring"

const MusicModal = React.memo(
  ({ platforms, isOpen, handleToggleModal, isMobile }) => {
    const modalSpringProps = useSpring({
      opacity: isOpen ? 1 : 0,
      transform: isOpen ? "translateY(0)" : "translateY(100%)",
    })

    const backgroundSpringProps = useSpring({
      opacity: isOpen ? 1 : 0,
    })

    return (
      <ScrollLock isActive={isOpen}>
        <animated.div
          className={`${
            !isOpen && "pointer-events-none overflow-hidden"
          } fixed bottom-0 inset-x-0 px-4 pb-6 sm:inset-0 sm:p-0 sm:flex sm:items-center sm:justify-center z-50`}
          style={{ ...backgroundSpringProps }}
        >
          <animated.div
            className={`fixed inset-0 transition-opacity`}
            style={{ ...backgroundSpringProps }}
            onClick={handleToggleModal}
            onKeyDown={handleToggleModal}
            role="button"
            tabIndex={0}
          >
            <div className="absolute inset-0 bg-gray-800 opacity-75"></div>
          </animated.div>
          <animated.div
            className={`bg-white rounded-lg px-4 pt-5 pb-4 overflow-hidden shadow-xl transform transition-all sm:max-w-sm sm:w-full sm:p-6`}
            style={{ ...modalSpringProps }}
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
          >
            <div className="absolute top-0 right-0 pt-4 pr-4">
              <button
                type="button"
                className="text-gray-400 hover:text-gray-500 focus:outline-none focus:text-gray-500 transition ease-in-out duration-150"
                aria-label="Close"
                onClick={handleToggleModal}
              >
                <svg
                  className="h-6 w-6"
                  stroke="currentColor"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
            <div>
              <div>
                <p className="leading-5 mb-2 font-bold">LISTEN</p>
                <div className="grid grid-cols-3 gap-2">
                  {platforms && platforms.appleMusic && (
                    <a
                      href={`${platforms.appleMusic.url}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="flex flex-col items-center text-xs p-2 hover:text-appleMusic transition ease-in-out duration-300"
                    >
                      <Icons icon="Apple Music" />
                    </a>
                  )}
                  {platforms && platforms.spotify && (
                    <a
                      href={`${
                        !isMobile
                          ? platforms.spotify.nativeAppUriDesktop
                          : platforms.spotify.url
                      }`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="flex flex-col items-center text-xs p-2 hover:text-spotify transition ease-in-out duration-300"
                    >
                      <Icons icon="Spotify" />
                    </a>
                  )}
                  {platforms && platforms.youtube && (
                    <a
                      href={`${platforms.youtube.url}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="flex flex-col items-center text-xs p-2 hover:text-youtube transition ease-in-out duration-300"
                    >
                      <Icons icon="YouTube" />
                    </a>
                  )}
                  {platforms && platforms.youtubeMusic && (
                    <a
                      href={`${platforms.youtubeMusic.url}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="flex flex-col items-center text-xs p-2 hover:text-youtube transition ease-in-out duration-300"
                    >
                      <Icons icon="YouTube Music" />
                    </a>
                  )}
                  {platforms && platforms.google && (
                    <a
                      href={`${platforms.google.url}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="flex flex-col items-center text-xs p-2 hover:text-googleMusic transition ease-in-out duration-300"
                    >
                      <Icons icon="Google" />
                    </a>
                  )}
                  {platforms && platforms.pandora && (
                    <a
                      href={`${platforms.pandora.url}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="flex flex-col items-center text-xs p-2 hover:text-pandora transition ease-in-out duration-300"
                    >
                      <Icons icon="Pandora" />
                    </a>
                  )}
                  {platforms && platforms.deezer && (
                    <a
                      href={`${platforms.deezer.url}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="flex flex-col items-center text-xs p-2 hover:text-deezer transition ease-in-out duration-300"
                    >
                      <Icons icon="Deezer" />
                    </a>
                  )}
                  {platforms && platforms.soundcloud && (
                    <a
                      href={`${platforms.soundcloud.url}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="flex flex-col items-center text-xs p-2 hover:text-soundCloud transition ease-in-out duration-300"
                    >
                      <Icons icon="SoundCloud" />
                    </a>
                  )}
                  {platforms && platforms.amazonMusic && (
                    <a
                      href={`${platforms.amazonMusic.url}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="flex flex-col items-center text-xs p-2 hover:text-amazonMusic transition ease-in-out duration-300"
                    >
                      <Icons icon="Amazon Music" />
                    </a>
                  )}
                  {platforms && platforms.tidal && (
                    <a
                      href={`${platforms.tidal.url}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="flex flex-col items-center text-xs p-2 hover:text-tidal transition ease-in-out duration-300"
                    >
                      <Icons icon="TIDAL" />
                    </a>
                  )}
                  {platforms && platforms.napster && (
                    <a
                      href={`${platforms.napster.url}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="flex flex-col items-center text-xs p-2 hover:text-napster transition ease-in-out duration-300"
                    >
                      <Icons icon="Napster" />
                    </a>
                  )}
                  {platforms && platforms.yandex && (
                    <a
                      href={`${platforms.yandex.url}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="flex flex-col items-center text-xs p-2 hover:text-yandex transition ease-in-out duration-300"
                    >
                      <Icons icon="Yandex" />
                    </a>
                  )}
                </div>
              </div>
              <div>
                <p className="leading-5 mt-4 mb-2 font-bold">BUY</p>
                <div className="grid grid-cols-3 gap-2">
                  {platforms && platforms.itunes && (
                    <a
                      href={`${
                        !isMobile
                          ? platforms.itunes.nativeAppUriDesktop
                          : platforms.itunes.nativeAppUriMobile
                      }`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="flex flex-col items-center text-xs p-2 hover:text-itunes transition ease-in-out duration-300"
                    >
                      <Icons icon="iTunes" />
                    </a>
                  )}
                  {platforms && platforms.googleStore && (
                    <a
                      href={`${platforms.googleStore.url}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="flex flex-col items-center text-xs p-2 hover:text-googlePlay transition ease-in-out duration-300"
                    >
                      <Icons icon="Google Play" />
                    </a>
                  )}
                  {platforms && platforms.amazonStore && (
                    <a
                      href={`${platforms.amazonStore.url}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="flex flex-col items-center text-xs p-2 hover:text-amazon transition ease-in-out duration-300"
                    >
                      <Icons icon="Amazon" />
                    </a>
                  )}
                </div>
              </div>
            </div>
          </animated.div>
        </animated.div>
      </ScrollLock>
    )
  }
)

export default MusicModal
