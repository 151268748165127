import React from "react"
import ReactAudioPlayer from "react-audio-player"
import { useSpring, animated } from "react-spring"

const MusicAudioPlayer = React.memo(
  ({ showPlayer, setShowPlayer, setMusicPlaying, song }) => {
    const [audioPlayerSpringProps, setAudioPlayerSpringProps] = useSpring(
      () => ({
        config: { duration: 100 },
        opacity: 0,
      })
    )

    return (
      <animated.div
        className="absolute inset-x-0 bottom-0 z-10 pb-2 px-6 transition ease-in-out duration-300"
        style={{ ...audioPlayerSpringProps }}
      >
        <h3 className="w-full text-2xl bg-white bg-opacity-75 mb-2 py-2 px-4 rounded transition ease-in-out duration-150 outline-none">
          {song.trackCensoredName}
        </h3>
        <ReactAudioPlayer
          className={`${
            !showPlayer && "hidden"
          } w-full transition ease-in-out duration-150 outline-none`}
          style={{ minWidth: "250px" }}
          src={song.previewUrl}
          controls
          onLoadedMetadata={() => {
            setShowPlayer(true)
            setAudioPlayerSpringProps({ opacity: 1 })
          }}
          onPlay={() => {
            setMusicPlaying(true)
          }}
          onPause={() => {
            setMusicPlaying(false)
          }}
          onEnded={() => {
            setMusicPlaying(false)
          }}
        />
      </animated.div>
    )
  }
)

export default MusicAudioPlayer
