import React, { Fragment } from "react"
import { graphql } from "gatsby"
import VisibilitySensor from "react-visibility-sensor"
import { useSpring, animated } from "react-spring"

import SEO from "../components/seo"
import Music from "../components/music/music"

import metaImage from "../images/bri-stauss.jpg"

export const query = graphql`
  query MusicPageQuery {
    allMusic {
      edges {
        node {
          artistId
          trackId
          localFile {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          previewUrl
          trackCensoredName
          linksByPlatform {
            amazonMusic {
              url
            }
            amazonStore {
              url
            }
            appleMusic {
              url
              nativeAppUriMobile
              nativeAppUriDesktop
            }
            deezer {
              url
            }
            # google {
            #   url
            # }
            # googleStore {
            #   url
            # }
            itunes {
              url
              nativeAppUriMobile
              nativeAppUriDesktop
            }
            napster {
              url
            }
            spotify {
              url
              nativeAppUriDesktop
            }
            tidal {
              url
            }
            yandex {
              url
            }
            youtube {
              url
            }
            youtubeMusic {
              url
            }
          }
        }
      }
    }
  }
`

const filterMusic = (music) => {
  return music
    .filter(({ node: song }) => {
      if (song.trackId === 1265164914) {
        return false
      }
      return true
    })
    .sort(({ node: a }, { node: b }) => {
      const textA = a.trackCensoredName.toUpperCase()
      const textB = b.trackCensoredName.toUpperCase()
      return textA < textB ? -1 : textA > textB ? 1 : 0
    })
}

export default ({
  data: {
    allMusic: { edges: music },
  },
}) => {
  const springProps = useSpring({
    opacity: 1,
    from: { opacity: 0 },
  })

  let singles = music.filter(({ node: song }) => song.artistId === 1002086123)
  singles = filterMusic(singles)
  let collabs = music.filter(
    ({ node: song }) =>
      song.artistId !== 1002086123 && song.artistId !== 1513107308
  )
  collabs = filterMusic(collabs)
  let chrisAndBri = music.filter(
    ({ node: song }) => song.artistId === 1513107308
  )
  chrisAndBri = filterMusic(chrisAndBri)

  const songs = [...chrisAndBri, ...singles, ...collabs]

  return (
    <>
      <SEO
        title="Music"
        description="Music by Bri Stauss"
        type="article"
        image={metaImage}
        pathname="music"
      />
      <animated.section
        className="p-4 pt-0"
        style={{ marginTop: "86px", ...springProps }}
      >
        <div className="flex flex-wrap -mx-2">
          {songs.map(({ node: song }) => {
            return (
              <Fragment key={song.trackId}>
                <VisibilitySensor
                  intervalDelay={100}
                  partialVisibility={true}
                  offset={{ top: -500, bottom: -500 }}
                >
                  {({ isVisible }) => {
                    return <Music song={song} isVisible={isVisible} />
                  }}
                </VisibilitySensor>
              </Fragment>
            )
          })}
        </div>
      </animated.section>
    </>
  )
}
