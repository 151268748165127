import React from "react"
import Img from "gatsby-image/withIEPolyfill"
import { animated } from "react-spring"

const MusicImage = ({ musicPlaying, song, springProps }) => {
  return (
    <div className={`${musicPlaying ? "music---active" : "music-container"}`}>
      <div style={{ paddingTop: "100%" }} className="relative">
        <animated.div style={{ ...springProps }}>
          {song.localFile && (
            <Img
              className="absolute inset-0"
              style={{
                height: "100%",
                position: "absolute",
                width: "100%",
              }}
              fluid={song.localFile.childImageSharp.fluid}
              alt={song.trackCensoredName}
              loading="eager"
            />
          )}
        </animated.div>
      </div>
    </div>
  )
}

export default MusicImage
