import React from "react"

const MusicButton = React.memo(({ showBuyButton, handleToggleModal }) => {
  return (
    <div
      className={`${
        !showBuyButton ? "opacity-0" : "opacity-100"
      } absolute top-0 z-10 pt-4 px-6 inset-x-0 transition ease-in-out duration-300`}
    >
      <span className="inline-flex rounded-md shadow-sm">
        <button
          type="button"
          className="inline-flex items-center px-4 py-2 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-black hover:opacity-75 focus:outline-none focus:border-gray-800 focus:shadow-outline-black active:bg-gray-800 transition ease-in-out duration-150"
          onClick={handleToggleModal}
        >
          LISTEN or BUY
        </button>
      </span>
    </div>
  )
})

export default MusicButton
