import React, { useState, useEffect, useCallback } from "react"
import { useSpring } from "react-spring"
import { isMobile } from "react-device-detect"

import MusicPlaceholder from "./musicPlaceholder"
import MusicModal from "./musicModal"
import MusicButton from "./musicButton"
import MusicImage from "./musicImage"
import MusicAudioPlayer from "./musicAudioPlayer"

const Music = React.memo(({ song, isVisible }) => {
  const [springProps, setSpringProps] = useSpring(() => ({
    config: { duration: 50 },
    opacity: 0,
  }))

  const [showBuyButton, setShowBuyButton] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [showPlayer, setShowPlayer] = useState(false)
  const [musicPlaying, setMusicPlaying] = useState(false)

  const handleToggleModal = useCallback(() => {
    if (isMobile) {
      setShowBuyButton(true)
    } else {
      setShowBuyButton(false)
    }
    setIsOpen(!isOpen)
  }, [isOpen])

  const handleMouseEnter = () => {
    return isMobile ? false : setShowBuyButton(true)
  }

  const handleMouseLeave = () => {
    return isMobile ? false : setShowBuyButton(false)
  }

  useEffect(() => {
    if (isMobile) {
      setShowBuyButton(true)
    }
  }, [])

  if (!isVisible) {
    setSpringProps({ opacity: 0 })
    if (isOpen) {
      setIsOpen(false)
    }
    return (
      <div className="w-full sm:w-1/2 xl:w-1/3 xxl:w-1/4 px-2 mb-4">
        <MusicPlaceholder />
      </div>
    )
  } else {
    setSpringProps({ opacity: 1 })
    return (
      <div
        className="relative w-full sm:w-1/2 xl:w-1/3 xxl:w-1/4 flex flex-col px-2 mb-4 cursor-default outline-none"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        role="button"
        tabIndex={0}
      >
        <MusicImage
          musicPlaying={musicPlaying}
          song={song}
          springProps={springProps}
        />
        <MusicButton
          showBuyButton={showBuyButton}
          handleToggleModal={handleToggleModal}
        />
        <MusicAudioPlayer
          showPlayer={showPlayer}
          setShowPlayer={setShowPlayer}
          setMusicPlaying={setMusicPlaying}
          song={song}
        />
        <MusicModal
          platforms={song.linksByPlatform}
          isOpen={isOpen}
          handleToggleModal={handleToggleModal}
          isMobile={isMobile}
        />
      </div>
    )
  }
})

export default Music
